<template>
  <!-- Modal -->
  <div
    class="modal"
    id="searchModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de sesiones</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Compañía:</label>
              <select v-model="item.cmp_id" class="form-control" v-on:change="setCompania">
                <option v-for="i in companias" v-bind:value="i._id">{{ i.cmp_nmbre }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Usuario:</label>
              <select v-model="item.usu_id" class="form-control">
                <option v-for="usuario in usuarios" v-bind:value="usuario._id">{{ usuario.usu_nmbre + ' ' + usuario.usu_aplldo + ' (' + usuario.usu_cdgo + ')' }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Desde fecha:</label>
              <input type="date" class="form-control" v-model="item.fechaini">
            </div>            
            <div class="form-group">
              <label>Hasta fecha:</label>
              <input type="date" class="form-control" v-model="item.fechafin">
            </div>            
            <div class="form-group">
              <label>Ip:</label>
              <input type="text" class="form-control" v-model="item.log_ip">
            </div>            
            <div class="form-group">
              <label>Navegador:</label>
              <input type="text" class="form-control" v-model="item.log_browser">
            </div>            
            <div class="form-group">
              <label>Sistema operativo:</label>
              <input type="text" class="form-control" v-model="item.log_os">
            </div>        
            <div class="form-group">
              <label>Usuarios activos:</label>
              <input type="checkbox" class="form-control" v-model="item.active">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              v-on:click="$emit('search', item)"
            >Buscar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      companias: [],
      usuarios: []
    };
  },
  mounted: function() {
  },
  created: function() {
    this.fetchCompania();
  },
  methods: {
    fetchCompania() {
      let uri = "/cmps";
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then(response => {
        this.companias = response.data;
      });
    },   
    setCompania(e) {
      var cmp = e.target.value;

      let uri = '/usuarios/' + cmp;
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then(response => {
        this.usuarios = response.data;
      });
    }
  } // END METHODS
};
</script>

<style>
/*
.modal-dialog {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 300px;
}
*/
</style>