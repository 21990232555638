<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Registro de sesiones de usuario</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-log-global v-on:search="searchItems" v-bind:item="search"></search-log-global>
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Compañía</td>
                      <td>Usuario</td>
                      <td>Fecha</td>
                      <td>Ip</td>
                      <td>Navegador</td>
                      <td>Sistema operativo</td>
                      <td>Tiempo de sesión (h:m:s)</td>
                      <!--
                      <td>Tiempo de actividad</td>
                      -->
                      <!--
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                      -->
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items">
                      <td class="text-center">
                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Ver</button>
                      </td>
                      <td>{{ item.cmp_nmbre }}</td>
                      <td>{{ item.usu_nmbre }}</td>
                      <td>{{ item.log_fecha | formatDateTime }}</td>
                      <td>{{ item.log_ip }}</td>
                      <td>{{ item.log_browser }}</td>
                      <td>{{ item.log_os }}</td>
                      <td>{{ item.log_tiempo_sesion | formatMsToHour }}</td>
                      <!--
                      <td>{{ item.log_tiempo_actividad }}</td>
                      -->
                      <!--
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                      -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <!--
            <div>
              <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                <i data-feather="delete"></i>Eliminar
              </button>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import SearchLogGlobal from "./SearchLogGlobal.vue";
import { formatDateTime, formatMsToHour } from '../../plugins/filters';

export default {
  components: {
    //VIcon,
    SearchLogGlobal
  },
  data(){
    return{
      items: [],
      companias: [],
      usuarios: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
    }
  },
  filters: {
    formatDateTime, formatMsToHour
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.searchItems();
    }
  },
  methods: {
    searchItems(p){
      //alert(key);
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
        this.message = 'Buscando logs';
        //p.cmp_id = this.$store.state.company;
        p.limit = 100;
        let uri = '/logs/search';
        this.axios.post(uri, p)
        .then(response => {
          console.log(response);
          var list = response.data;
          this.search = p;
          this.delItems = [];
          this.filter = true;

          let uri = '/usuarios/all';
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri).then(response => {
            this.usuarios = response.data;

            for (var i = 0; i < list.length; i++) {
              for (var j = 0; j < this.usuarios.length; j++) {
                if (list[i].usu_id  == this.usuarios[j]._id) {
                  list[i].cmp_nmbre = this.usuarios[j].compania_usuarios[0].cmp_nmbre;
                  list[i].usu_nmbre = this.usuarios[j].usu_nmbre + " " + this.usuarios[j].usu_aplldo + " (" + this.usuarios[j].usu_cdgo + ")";
                  j = this.usuarios.length;
                }
              }
            }
            this.items = list;
          });
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los logs' + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    editItem(id){
      this.$router.push({ name: 'CreateLogGlobal', params: { id: id, add: false, search: this.search } });
    }
  }
}
</script>